import { extend, localize } from 'vee-validate'
import {
  required, min, max, email, max_value,
  required_if,
} from 'vee-validate/dist/rules'
import lang from 'vee-validate/dist/locale/id.json'

extend('required_if', required_if)
extend('required', required)
extend('email', email)
extend('min', min)
extend('max', max)
extend('max_value', max_value)

localize('id', lang)
