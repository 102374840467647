import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  congratulations: {
    name: 'John',
    saleToday: '48900'
  },
  statisticsLandItems: [
    {
      icon: 'TruckIcon',
      color: 'light-danger',
      title: '1315',
      subtitle: 'Jumlah Truck',
      customClass: 'mb-1 mb-sm-1'
    },
    {
      icon: 'WatchIcon',
      color: 'light-primary',
      title: '118 ✓',
      subtitle: 'Tepat Waktu',
      customClass: 'mb-1 mb-xl-1',
      popTitle: 'Clock IN ( Tepat Waktu )',
      popContent:
        'Data ini dihitung ketika Truck Clock IN (Scan Masuk) sebelum waktu Schedule IN yang telah ditetapkan.'
    },
    {
      icon: 'WatchIcon',
      color: 'light-primary',
      title: '118 ✓',
      subtitle: 'Sesuai Planning',
      customClass: 'mb-1 mb-xl-1',
      popTitle: 'Clock OUT ( Sesuai Planning )',
      popContent:
        'Data ini dihitung ketika Truck Clock OUT (Scan Keluar) dengan ketentuan Diff clock tidak melebihi Diff Schedule.'
    },
    {
      icon: 'ClipboardIcon',
      color: 'light-info',
      title: '1318 🎯',
      subtitle: 'Jumlah Form',
      customClass: 'mb-1 mb-xl-1',
      popTitle: 'Jumlah Form',
      popContent: 'Data ini dihitung ketika form masuk telah diterbitkan.'
    }
    // {
    //   icon: 'WatchIcon',
    //   color: 'light-primary',
    //   title: '118 ✓',
    //   subtitle: 'Clock IN',
    //   customClass: 'mb-1 mb-xl-1',
    //   popTitle: '',
    //   popContent: '',
    // },
    // {
    //   icon: 'WatchIcon',
    //   color: 'light-primary',
    //   title: '118 ✓',
    //   subtitle: 'Clock OUT',
    //   customClass: 'mb-1 mb-xl-1',
    //   popTitle: '',
    //   popContent: '',
    // },
    // {
    //   icon: 'ClipboardIcon',
    //   color: 'light-info',
    //   title: '118 🎯',
    //   subtitle: 'Form Published',
    //   customClass: 'mb-1 mb-xl-1',
    //   popTitle: '',
    //   popContent: '',
    // },
    // {
    //   icon: 'AnchorIcon',
    //   color: 'light-success',
    //   title: '118',
    //   subtitle: 'Jumlah Boat',
    //   customClass: '',
    // },
  ],
  statisticsRiverItems: [
    {
      icon: 'AnchorIcon',
      color: 'light-success',
      title: '118',
      subtitle: 'Jumlah Boat',
      customClass: ''
    },
    {
      icon: 'WatchIcon',
      color: 'light-primary',
      title: '118 ✓',
      subtitle: 'Clock IN',
      customClass: 'mb-1 mb-xl-1',
      popTitle: '',
      popContent: ''
    },
    {
      icon: 'WatchIcon',
      color: 'light-primary',
      title: '118 ✓',
      subtitle: 'Clock OUT',
      customClass: 'mb-1 mb-xl-1',
      popTitle: '',
      popContent: ''
    },
    {
      icon: 'ClipboardIcon',
      color: 'light-info',
      title: '118 🎯',
      subtitle: 'Form Published',
      customClass: 'mb-1 mb-xl-1',
      popTitle: '',
      popContent: ''
    }
  ],
  statisticsOrder: {
    truck_in_area_today: '0',
    series: [
      {
        name: 'Total IN OUT',
        categories: [
          'Senin, 25 Juli',
          'Selasa',
          'Rabu',
          'Kamis',
          'Jumat',
          'Sabtu'
        ],
        data: [45, 85, 65, 45, 65, 77]
      }
    ]
  },
  statisticsProfit: {
    series: [
      {
        name: 'Total IN OUT',
        categories: [
          'Senin, 25 Juli',
          'Selasa',
          'Rabu',
          'Kamis',
          'Jumat',
          'Sabtu'
        ],
        data: [0, 20, 5, 30, 15, 45]
      }
    ]
  },
  statisticRiver: {
    series: [
      {
        name: 'Rencana Sandar',
        categories: [
          'Senin, 25 Juli',
          'Selasa, 25 Juli',
          'Rabu, 25 Juli',
          'Kamis, 25 Juli',
          'Jumat, 25 Juli',
          'Sabtu, 25 Juli'
        ],
        data: [0, 20, 5, 30, 15, 45]
      },
      {
        name: 'Kapal Sandar',
        categories: [
          'Senin, 25 Juli',
          'Selasa, 25 Juli',
          'Rabu, 25 Juli',
          'Kamis, 25 Juli',
          'Jumat, 25 Juli',
          'Sabtu, 25 Juli'
        ],
        data: [10, 40, 5, 30, 15, 45]
      },
      {
        name: 'Kapal Berangkat',
        categories: [
          'Senin, 25 Juli',
          'Selasa, 25 Juli',
          'Rabu, 25 Juli',
          'Kamis, 25 Juli',
          'Jumat, 25 Juli',
          'Sabtu, 25 Juli'
        ],
        data: [30, 70, 5, 30, 15, 45]
      },
      {
        name: 'Kegiatan Dibatalkan',
        categories: [
          'Senin, 25 Juli',
          'Selasa, 25 Juli',
          'Rabu, 25 Juli',
          'Kamis, 25 Juli',
          'Jumat, 25 Juli',
          'Sabtu, 25 Juli'
        ],
        data: [20, 10, 5, 30, 15, 45]
      }
    ]
  },
  earningsChart: {
    series: [53, 16]
  },
  revenue: {
    years: ['2020', '2019'],
    price: '25,852',
    budget: '56,800',
    categories: ['Senin, 31 juli', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
    revenueReport: {
      series: [
        {
          name: 'Un Available',
          data: [10, 177, 284, 256]
        },
        {
          name: 'Available',
          data: [20, 177, 284, 256]
        },
        {
          name: 'Used',
          data: [100, 177, 284, 256]
        },
        {
          name: 'Expired',
          data: [420, 177, 284, 256]
        }
      ]
    },
    budgetChart: {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62]
        },
        {
          data: [20, 10, 30, 15, 23, 10, 25, 15, 20, 5, 27]
        },
        {
          data: [2, 5, 20, 10, 13, 0, 14, 10, 10, 1, 2]
        },
        {
          data: [10, 5, 20, 10, 13, 0, 14, 10, 10, 1, 2]
        }
      ]
    }
  },
  companyTable: [
    {
      avatarImg: require('@/assets/images/icons/toolbox.svg'),
      title: 'Dixons',
      subtitle: 'meguc@ruj.io',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      avatarTitle: 'Technology',
      viewTitle: '23.4k',
      viewsub: 'in 24 hours',
      revenue: '891.2',
      sales: '68',
      loss: true
    },
    {
      avatarImg: require('@/assets/images/icons/parachute.svg'),
      title: 'Motels',
      subtitle: 'vecav@hodzi.co.uk',
      avatarIcon: 'CoffeeIcon',
      avatarColor: 'light-success',
      avatarTitle: 'Grocery',
      viewTitle: '78k',
      viewsub: 'in 2 days',
      revenue: '668.51',
      sales: '97',
      loss: false
    },
    {
      avatarImg: require('@/assets/images/icons/brush.svg'),
      title: 'Zipcar',
      subtitle: 'davcilse@is.gov',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Fashion',
      viewTitle: '162',
      viewsub: 'in 5 days',
      revenue: '522.29',
      sales: '62',
      loss: false
    },
    {
      avatarImg: require('@/assets/images/icons/star.svg'),
      title: 'Owning',
      subtitle: 'us@cuhil.gov',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      avatarTitle: 'Technology',
      viewTitle: '214',
      viewsub: 'in 24 hours',
      revenue: '291.01',
      sales: '88',
      loss: false
    },
    {
      avatarImg: require('@/assets/images/icons/book.svg'),
      title: 'Cafés',
      subtitle: 'pudais@jife.com',
      avatarIcon: 'CoffeeIcon',
      avatarColor: 'light-success',
      avatarTitle: 'Grocery',
      viewTitle: '208',
      viewsub: 'in 1 week',
      revenue: '783.93',
      sales: '16',
      loss: true
    },
    {
      avatarImg: require('@/assets/images/icons/rocket.svg'),
      title: 'Kmart',
      subtitle: 'bipri@cawiw.com',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Fashion',
      viewTitle: '990',
      viewsub: 'in 1 month',
      revenue: '780.05',
      sales: '78',
      loss: false
    },
    {
      avatarImg: require('@/assets/images/icons/speaker.svg'),
      title: 'Payers',
      subtitle: 'luk@izug.io',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Fashion',
      viewTitle: '12.9k',
      viewsub: 'in 12 hours',
      revenue: '531.49',
      sales: '42',
      loss: false
    }
  ],
  meetup: {
    mediaData: [
      {
        avatar: 'CalendarIcon',
        title: 'Sat, May 25, 2020',
        subtitle: '10:AM to 6:PM'
      },
      {
        avatar: 'MapPinIcon',
        title: 'Central Park',
        subtitle: 'Manhattan, New york City'
      }
    ],
    avatars: [
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
        fullName: 'Billy Hopkins'
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
        fullName: 'Amy Carson'
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
        fullName: 'Brandon Miles'
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
        fullName: 'Daisy Weber'
      },
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
        fullName: 'Jenny Looper'
      }
    ]
  },
  goalOverview: {
    completed: '786,617',
    inProgress: '13,561',
    series: [83]
  },
  transactionData: [
    {
      mode: 'Wallet',
      types: 'Starbucks',
      avatar: 'PocketIcon',
      avatarVariant: 'light-primary',
      payment: '-$74',
      deduction: true
    },
    {
      mode: 'Bank Transfer',
      types: 'Add Money',
      avatar: 'CheckIcon',
      avatarVariant: 'light-success',
      payment: '+$480',
      deduction: false
    },
    {
      mode: 'Paypal',
      types: 'Add Money',
      avatar: 'DollarSignIcon',
      avatarVariant: 'light-danger',
      payment: '+$480',
      deduction: false
    },
    {
      mode: 'Mastercard',
      types: 'Ordered Food',
      avatar: 'CreditCardIcon',
      avatarVariant: 'light-warning',
      payment: '-$23',
      deduction: true
    },
    {
      mode: 'Transfer',
      types: 'Refund',
      avatar: 'TrendingUpIcon',
      avatarVariant: 'light-info',
      payment: '+$98',
      deduction: false
    }
  ]
}
/* eslint-disable global-require */
mock.onGet('/ecommerce/data').reply(() => [200, data])
