import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = (next) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer'
    ) {
      return next()
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}
const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (role === 'client' || role === 'host' || role === 'security') {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'auth-login' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/company-own/land-vehicles',
    name: 'company-own-land-vehicles',
    component: () => import('@/views/pages/companyown/LandVehicleOwn.vue'),
    meta: { guard }
  },
  {
    path: '/company-own/delivery-route',
    name: 'company-own-delivery-route',
    component: () => import('@/views/pages/companyown/RouteDestination.vue'),
    meta: { guard }
  },
  {
    path: '/company-own/agen',
    name: 'company-own-agen',
    component: () => import('@/views/pages/companyown/AgenOwn.vue'),
    meta: { guard }
  },
  {
    path: '/company-own/transportir',
    name: 'company-own-transportir',
    component: () => import('@/views/pages/companyown/TransportirOwn.vue'),
    meta: { guard }
  },
  {
    path: '/destination/bongkar',
    name: 'destination-bongkar',
    component: () => import('@/views/pages/destination/Bongkar.vue'),
    meta: { guard }
  },
  {
    path: '/destination/muat',
    name: 'destination-muat',
    component: () => import('@/views/pages/destination/Muat.vue'),
    meta: { guard }
  }
]
