import {
  getHomeRouteForLoggedInUser,
  getUserData,
  isUserLoggedIn
} from '@/auth/utils'

const guard = (next) => {
  if (!isUserLoggedIn()) {
    return next()
  }
  return next(getHomeRouteForLoggedInUser(getUserData().role))
}

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/register',
    name: 'auth-register-v1',
    // component: () => import('@/views/pages/authentication/Register-v1.vue'),
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
      // redirectIfLoggedIn: true
    }
  }
].map((route) => {
  route.meta = route.meta ? { ...route.meta, guard } : { guard }
  return route
})
