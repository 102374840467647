import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = (next) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer' ||
      role === 'jetty man'
    ) {
      return next()
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}
const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer' ||
      role === 'jetty man'
    ) {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'auth-login' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/realtime-monitoring/land-route',
    name: 'realtime-monitoring-land-route',
    component: () =>
      import('@/views/pages/dashboard/landgraphanalytic/Landgraphanalytic.vue'),
    // component: () => import('@/views/pages/dashboard/analytics/Analytics.vue'),
    meta: {
      pageTitle: 'Analytic',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Graph Analytic',
          active: true
        }
      ],
      guard
    }
  },
  {
    path: '/realtime-monitoring/river-route/graph',
    name: 'realtime-monitoring-river-route-graph',
    component: () =>
      import('@/views/pages/dashboard/realtime-monitoring/RMGraphSungai.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Analytic',
      breadcrumb: [
        {
          text: '⚓️',
          active: true
        },
        {
          text: 'Graph Analytic',
          active: true
        }
      ],
      guard
    }
    // meta: {
    //   guard: next => guardCustom(next, ['all', 'special', 'qrcode'], ['SAL', 'TBBM', 'Hulu Migas']),
    // },
  },
  {
    path: '/realtime-monitoring/river-route/table',
    name: 'realtime-monitoring-river-route-table',
    component: () =>
      import('@/views/pages/dashboard/realtime-monitoring/RMTableSungai.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: { guard }
    // meta: {
    //   guard: next => guardCustom(next, ['all', 'special', 'qrcode'], ['SAL', 'TBBM', 'Hulu Migas']),
    // },
  },
  {
    path: '/realtime-monitoring/river-route/table/permohonan-kapal-tbbm',
    name: 'realtime-monitoring-river-route-table-permohonan-kapal-tbbm',
    component: () =>
      import(
        '@/views/pages/dashboard/realtime-monitoring/RMTablePermohonanKapal.vue'
      ),
    // meta: { guard } // uncomment if fixed data on viewer.
    meta: {
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'qrcode', 'jetty', 'special'],
          ['SAL', 'TBBM']
        )
    }
  },
  {
    path: '/realtime-monitoring/river-route/table/permohonan-kapal-hulu-migas',
    name: 'realtime-monitoring-river-route-table-permohonan-kapal-hulu-migas',
    component: () =>
      import(
        '@/views/pages/dashboard/realtime-monitoring/RMTablePermohonanKapalHM.vue'
      ),
    // meta: { guard } // uncomment if fixed data on viewer.
    meta: {
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode', 'jetty'], ['SAL', 'Hulu Migas'])
    }
  },
  {
    path: '/realtime-monitoring/river-route/table/kapal-sandar',
    name: 'realtime-monitoring-river-route-table-sandar',
    component: () =>
      import(
        '@/views/pages/dashboard/realtime-monitoring/RMTableSungaiSandar.vue'
      ),
    meta: { guard } // uncomment if fixed data on viewer.
    // meta: {
    //   guard: (next) =>
    //     guardCustom(
    //       next,
    //       ['all', 'qrcode', 'jetty'],
    //       ['Hulu Migas', 'SAL', 'TBBM', 'approvement']
    //     )
    // }
  },
  {
    path: '/realtime-monitoring/river-route/table/kapal-rencana-sandar/sal',
    name: 'realtime-monitoring-river-route-table-sandar-rencana-sal',
    component: () =>
      import(
        '@/views/pages/dashboard/realtime-monitoring/RMTableSungaiSAL.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    // meta: { guard }
    meta: {
      guard: (next) => guardCustom(next, ['all', 'qrcode', 'jetty'], ['SAL'])
    }
  },
  {
    path: '/realtime-monitoring/river-route/table/kapal-rencana-sandar',
    name: 'realtime-monitoring-river-route-table-sandar-rencana',
    component: () =>
      import(
        '@/views/pages/dashboard/realtime-monitoring/RMTableSungaiRencanaSandar.vue'
      ),
    meta: { guard } // uncomment if fixed data on viewer.
    // meta: {
    //   guard: (next) =>
    //     guardCustom(
    //       next,
    //       ['all', 'qrcode', 'jetty'],
    //       ['Hulu Migas', 'SAL', 'TBBM', 'approvement']
    //     )
    // }
  },
  {
    path: '/realtime-monitoring/river-route/table/kapal-berangkat',
    name: 'realtime-monitoring-river-route-table-berangkat',
    component: () =>
      import(
        '@/views/pages/dashboard/realtime-monitoring/RMTableSungaiBerangkat.vue'
      ),
    meta: { guard } // uncomment if fixed data on viewer.
    // meta: {
    //   guard: (next) =>
    //     guardCustom(
    //       next,
    //       ['all', 'qrcode', 'jetty'],
    //       ['Hulu Migas', 'SAL', 'TBBM', 'approvement']
    //     )
    // }
  },
  {
    path: '/realtime-monitoring/river-route/table/kapal-kegiatan-dibatalkan',
    name: 'realtime-monitoring-river-route-table-cancel',
    component: () =>
      import(
        '@/views/pages/dashboard/realtime-monitoring/RMTableSungaiCancel.vue'
      ),
    meta: { guard } // uncomment if fixed data on viewer.
    // meta: {
    //   guard: (next) =>
    //     guardCustom(
    //       next,
    //       ['all', 'qrcode', 'jetty'],
    //       ['Hulu Migas', 'SAL', 'TBBM', 'approvement']
    //     )
    // }
  },
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue')
  }
]
