import Vue from 'vue'

import {
  ToastPlugin,
  ModalPlugin,
  PaginationPlugin,
  BootstrapVue
} from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTour from 'vue-tour'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@/libs/clipboard'
import moment from 'moment'
import firebase from 'firebase/app'
import router from './router'
import i18n from './libs/i18n'
import store from './store'
import App from './App.vue'

import 'moment/locale/id'
import './vee-validate'
import VueParticles from 'vue-particles'
import $ from 'jquery'

// CSS
import 'sweetalert2/dist/sweetalert2.min.css'
// import 'choices.js/public/assets/styles/base.min.css'
import 'choices.js/public/assets/styles/choices.min.css'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/toastification'
import '@/libs/vue-select'

// Initialize Firebase
import 'firebase/messaging'
// Axios Mock Adapter
import '@/@fake-db/db'

require('@core/assets/fonts/feather/iconfont.css')

// import DisableAutocomplete from 'vue-disable-autocomplete'

// Vue.use(DisableAutocomplete)

// import { ValidationObserver, ValidationProvider } from 'vee-validate'
// // import VeeValidate from 'vee-validate'

require('dotenv').config()

moment.locale('en')
// import validationMessages from 'vee-validate/dist/locale/id'
Vue.config.ignoredElements = [
  'multiple-header-end',
  'multiple-header',
  'sal-access'
]

Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
    })
  }
})

// SWEETALERT
Vue.use(VueSweetalert2)
// Vue Tour
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
// BSV
Vue.use(BootstrapVue)

// BSV Plugin Registration
Vue.use(ToastPlugin)

Vue.use(VueParticles)
/* CUSTOM VARIABLE HERE {override} */
Vue.prototype.$notificationMsg = {
  /* eslint-disable key-spacing */
  rkbm: 'Document RKBM Telah diajukan.',
  rencana_sandar: 'Berencana Sandar',
  sandar: 'Telah Sandar',
  berangkat: 'Telah Berangkat',
  batal: 'Membatalkan Kegiatan' /* TBBM / Hulu Migas */,
  no_pindah: 'Menerbitkan Nomor Pindah',

  setuju_admin: 'Form Telah disetujui' /* TBBM Only */,
  // `Oleh Admin ${this.form.schedule.pemilik || '-'}`
  setuju_sal: 'Document RPKRO Sudah diterbitkan' /* TBBM Only */, //
  document_lengkap: 'Document lengkap, Kapal Boleh Sandar' /* TBBM Only */
  /* hard code */
  // `Pindah Jetty Dari #POSISI_JETTY_${this.tempCheckAlrSandarBrs} #${this.tempCheckAlrSandar}, `,
  // `Ke #POSISI_JETTY_${pos} #${baris}`

  // `#POSISI_JETTY_${pos} #${baris}`
}

/* TABLE COLUMN FORM_STATUS */
Vue.prototype.$mimeType = {
  /* eslint-disable key-spacing */
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.ms-excel': 'xls',
  'image/jpeg': 'jpeg',
  'image/png': 'png'
}

/* END OF CUSTOM VARIABLE HERE */

// Create $alert prototype extends from Vue Toast Plugin
Vue.prototype.$alert = function (props) {
  this.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props
  })
}
// eslint-disable-next-line func-names
Vue.prototype.$getPropByString = function (obj, propString) {
  if (!propString) {
    return obj
  }

  let prop
  const props = propString.split('.')

  // eslint-disable-next-line vars-on-top, no-var, no-plusplus
  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i]

    const candidate = obj[prop]
    if (candidate !== undefined) {
      // eslint-disable-next-line no-param-reassign
      obj = candidate
    } else {
      break
    }
  }
  // eslint-disable-next-line block-scoped-var
  return obj[props[i]]
}

/* CONSTANT DONT CHANGE ABOVE CODE */
Vue.prototype.$formSegmenSlb = [
  {
    key: 'MI (LMP)'
  },
  {
    key: 'DAS (Cementing)'
  },
  {
    key: 'Facility (SLB)'
  },
  {
    key: 'RPI'
  },
  {
    key: 'ELNUSA (Cementing)'
  },
  {
    key: 'SAL'
  }
]

Vue.prototype.$forceToRefresh = 'force to refresh'
Vue.prototype.$forceToLogout = 'force to logout'

Vue.prototype.$KAPAL_STATUS = {
  /* HULU MIGAS */
  kapal_rencana_sandar: 'KAPAL RENCANA SANDAR',
  kapal_sandar: 'KAPAL SANDAR',
  kapal_keluar: 'KAPAL BERANGKAT',
  kegiatan_dibatalkan: 'KEGIATAN DIBATALKAN',

  /* eslint-disable key-spacing */
  /* TBBM */
  no_perijinan_belum_lengkap: 'NO PERIJINAN BELUM LENGKAP',

  belum_disetujui: 'BELUM DISETUJUI' /* REMOVE */,
  kegiatan_dimulai: 'KEGIATAN DIMULAI' /* REMOVE  */,
  kegiatan_selesai: 'KEGIATAN SELESAI' /* REMOVE */
  /* eslint-enable key-spacing */
}

Vue.prototype.$variantColumnFormStatus = {
  /*  */
  'KAPAL RENCANA SANDAR': 'primary',
  'KAPAL SANDAR': 'warning',
  'KAPAL BERANGKAT': 'success',
  'KEGIATAN DIBATALKAN': 'danger',

  /* eslint-disable key-spacing */
  'NO PERIJINAN BELUM LENGKAP': 'warning',
  'BELUM DISETUJUI': 'warning',
  'BELUM MELAKUKAN KEGIATAN': 'primary',
  'KEGIATAN DIMULAI': 'info',
  'KEGIATAN SELESAI': 'info',
  /* eslint-enable key-spacing */

  /* custom value */
  'BELUM INPUT RENCANA KEGIATAN': 'warning'
}
/* verified status */
Vue.prototype.$variantVerified = {
  /* eslint-disable key-spacing */
  Warning: 'warning',
  'Request Re-Verification': 'primary',
  'Completed By System': 'success',
  'Completed By SAL': 'success'
}

/* DROPDOWN ON DETAIL RIVER TABLE */
Vue.prototype.$variantDDFormStatus = {
  'KAPAL RENCANA SANDAR': 'primary',
  'KAPAL SANDAR': 'warning',
  'KAPAL BERANGKAT': 'success',
  'KEGIATAN DIBATALKAN': 'danger',

  /* eslint-disable key-spacing */
  'NO PERIJINAN BELUM LENGKAP': 'warning',
  'BELUM DISETUJUI': 'warning',
  'BELUM MELAKUKAN KEGIATAN': 'primary',
  'KEGIATAN DIMULAI': 'success',
  'KEGIATAN SELESAI': 'success'
  /* eslint-enable key-spacing */
}

Vue.prototype.$variantColumnDetails = {
  'KAPAL RENCANA SANDAR': 'primary',
  'KAPAL SANDAR': 'warning',
  'KAPAL BERANGKAT': 'success',
  'KEGIATAN DIBATALKAN': 'danger',

  'NO PERIJINAN BELUM LENGKAP': 'warning',
  'BELUM DISETUJUI': 'primary',
  'BELUM MELAKUKAN KEGIATAN': 'primary',
  'KEGIATAN SELESAI': 'primary',
  'KEGIATAN DIMULAI': 'primary'
}

Vue.prototype.$formStatusVariant = {
  /* eslint-disable key-spacing */
  'Auto Approve': 'success',
  'Pending Approve': 'warning',

  'MI (LMP)': 'light-primary',
  'DAS (Cementing)': 'light-secondary',
  'Facility (SLB)': 'light-success',
  RPI: 'light-success',
  'ELNUSA (Cementing)': 'light-warning',
  SAL: 'light-info'
  /* eslint-enable key-spacing */
}
Vue.prototype.$formStatus = {
  /* eslint-disable key-spacing */
  'Auto Approve': 'Approved',
  'Pending Approve': 'Pending Approve'
  /* eslint-enable key-spacing */
}

Vue.prototype.$valueBEapprove = 'Auto Approve'
Vue.prototype.$valueBEpendingApprove = 'Pending Approve'

Vue.prototype.$variantBadge = {
  /* eslint-disable key-spacing */
  'Un Available': 'light-warning',
  Available: 'light-success',
  Used: 'light-info',
  Expired: 'light-danger'
  /* eslint-enable key-spacing */
}

Vue.prototype.$JENIS_KAPAL = {
  /* eslint-disable key-spacing */
  lct_km_mt_sea_truck: 'LCT / KM / MT / Sea Truck',
  tug_boat: 'Tug Boat',
  barge: 'Barge'
  /* eslint-enable key-spacing */
}

/* CONSTANT DONT CHANGE ABOVE CODE */

Vue.use(PaginationPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$i18 = i18n

// firebase
firebase.initializeApp({
  apiKey: 'AIzaSyCefyxZYj-RluHGO43Y-NqM8AJjLGWEH5o',
  authDomain: 'project-5566467322804966315.firebaseapp.com',
  databaseURL: 'https://project-5566467322804966315.firebaseio.com',
  projectId: 'project-5566467322804966315',
  storageBucket: 'project-5566467322804966315.appspot.com',
  messagingSenderId: '364839005563',
  appId: '1:364839005563:web:c439d4a2478a2d8ff121de',
  measurementId: 'G-QBNCYVSL7E'
})

navigator.serviceWorker
  .register('firebase-messaging-sw.js')
  .then((registration) => {
    const messaging = firebase.messaging()
    messaging.useServiceWorker(registration)
    console.log('success to register sw firebase-messaging-sw.js: ')
  })
  .catch((err) => {
    console.log('an error to register sw firebase-messaging-sw.js: ', err)
  })
// fiebase configs end

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
