import Vue from 'vue'

// axios
import axios from 'axios'

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${BACKEND_URL.replace(/\/+$/, '')}/api/`,
  // timeout: 1000,
})

Vue.prototype.$http = axiosIns

export default axiosIns
