export default [
  {
    path: '/sa/guide',
    name: 'sa-guide',
    component: () => import('@views/pages/faq/Faq.vue')
  },

  // {
  //   path: '/',
  //   name: 'landing-page',
  //   component: () => import('@/views/pages/landing-page/index.vue'),
  //   meta: {
  //     layout: 'full'
  //   }
  // },
  // {
  //   path: '/sa/guide1',
  //   name: 'sa-guide1',
  //   component: () => import('@views/pages/faq/Faq.vue')
  // },
  // {
  //   path: '/sa/company/profile',
  //   name: 'sa-company-profile',

  // },
  {
    path: '/change/password',
    name: 'change-password',
    component: () => import('@views/pages/authentication/ResetPassword-v2.vue')
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue')
  }
]
