import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    // TODO: Remove
    reviewer: {
      ts: '',
      department: []
    },
    operatorLkpsPage: 1,
    reviewerLkpsPage: 1,

    advanceSearchData: {},
    advanceSearchDataConcenate: '',
    watchNotificationRiver: null,
    watchNotification: 1,
    watchNotificationClockIN: 1,
    watchKegiatanFromStatus: 1,
    tokenNotification: '',
    watchException: '',
    roleName: '',
    dailyVehicle: null,
    watchApproval: null
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getReviewerInfo: (state) => state.reviewer,
    getAdvanceSearchData: (state) => state.advanceSearchData,
    getAdvanceSearchDataConcenate: (state) => state.advanceSearchDataConcenate,
    getWatchNotification: (state) => state.watchNotification,
    getWatchNotificationClockIN: (state) => state.watchNotificationClockIN,
    getTokenNotification: (state) => state.tokenNotification,
    getWatchwatchKegiatanFromStatus: (state) => state.watchKegiatanFromStatus,
    getWatchException: (state) => state.watchException,
    getRoleName: (state) => state.roleName,
    getWatchNotificationRiver: (state) => state.watchNotificationRiver
  },
  mutations: {
    UPDATE_WATCH_dailyVehicle(state, val) {
      state.dailyVehicle = val
    },
    UPDATE_WATCH_watchApproval(state, val) {
      state.watchApproval = val
    },
    UPDATE_ROLE_NAME(state, val) {
      state.roleName = val
    },
    UPDATE_WATCH_NOTIFICATION_RIVER(state, val) {
      state.watchNotificationRiver = val
    },
    UPDATE_WATCH_EXCEPTION(state, val) {
      state.watchException = val
    },
    UPDATE_TOKEN_NOTIFICATION(state, val) {
      state.tokenNotification = val
    },
    UPDATE_WATCH_NOTIFICATION(state, val) {
      state.watchNotification += 1
    },
    UPDATE_WATCH_NOTIFICATION_CLOCK_IN(state, val) {
      state.watchNotificationClockIN += 1
    },
    UPDATE_WATCH_KEGIATAN_FROM_STATUS(state, val) {
      state.watchKegiatanFromStatus += 1
    },
    UPDATE_ADVANCE_SEARCH_DATA(state, val) {
      state.advanceSearchData = val
    },
    UPDATE_ADVANCE_SEARCH_DATA_CONCENATE(state, val) {
      state.advanceSearchDataConcenate = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_REVIEWER_INFO(state, val) {
      state.reviewer = val
    },
    UPDATE_OPERATOR_LKPS_PAGE(state, val) {
      state.operatorLkpsPage = val
    },
    UPDATE_REVIEWER_LKPS_PAGE(state, val) {
      state.reviewerLkpsPage = val
    }
  },
  actions: {}
}
