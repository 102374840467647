import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = (next) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer' ||
      role === 'jetty man'
    ) {
      return next()
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer' ||
      role === 'jetty man'
    ) {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'misc-not-authorized' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/river-route/analytic/count-tambat',
    name: 'river-route-analytic-count-tambat',
    component: () =>
      import(
        '@/views/pages/analytic/jalur-sungai/boat-analytic/RiverCountTambat.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Analytic',
      breadcrumb: [
        {
          text: '⚓️',
          active: true
        },
        {
          text: 'Boat Analytic',
          active: true
        }
      ],
      guard
    }
  }
]
