import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (role === 'client' || role === 'host') {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'auth-login' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/data-reporting/land-route',
    name: 'data-reporting-land-route',
    component: () => import('@/views/pages/reporting/jalur-darat/index.vue'),
    meta: {
      guard: (next) => guardCustom(next, ['all', 'DATA'], ['SAL', 'EXPORT'])
    }
  },
  {
    path: '/data-reporting/river-route',
    name: 'data-reporting-river-route',
    component: () =>
      import(
        '@/views/pages/reporting/jalur-sungai/kegiatan-operasional/index.vue'
      ),
    meta: {
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'DATA', 'special'],
          [
            'SAL',
            'EXPORT',
            'TBBM',
            'TBBM JETTY 3A',
            'Hulu Migas',
            'approvement'
          ]
        )
    }
  },
  {
    path: '/data-reporting/river-route/supervisi',
    name: 'data-reporting-river-route-supervisi',
    component: () =>
      import(
        '@/views/pages/reporting/jalur-sungai/supervisi-monitoring/index.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'DATA', 'special'],
          [
            'SAL',
            'EXPORT',
            'TBBM',
            'TBBM JETTY 3A',
            'Hulu Migas',
            'approvement'
          ]
        )
    }
  }
]
