import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = (next) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer'
    ) {
      return next()
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}
const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer'
    ) {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'misc-not-authorized' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  /* NAVIGASI TERBITKAN FORM --> JALUR DARAT */
  {
    path: '/monitoring/jalur-darat/form/type-1',
    name: 'monitoring-jalur-darat-form-type-1',
    component: () =>
      import('@/views/pages/monitoring/jalur-darat/form-type-1/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Form Type 1 (T1)',
          active: true
        },
        {
          text: 'index',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form1', 'qrcode', 'special'])
    }
  },
  {
    path: '/monitoring/jalur-darat/form/type-1/wizard',
    name: 'monitoring-jalur-darat-form-type-1-wizard',
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-darat/form-type-1/wizard/index.vue'
      ),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Form Type 1 (T1)',
          to: '/monitoring/jalur-darat/form/type-1'
        },
        {
          text: 'Buat Form',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form1', 'qrcode', 'special'])
    }
  },
  {
    path: '/monitoring/jalur-darat/form/type-2',
    name: 'monitoring-jalur-darat-form-type-2',
    component: () =>
      import('@/views/pages/monitoring/jalur-darat/form-type-2/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Form Type 2 (T2)',
          active: true
        },
        {
          text: 'index',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form2', 'qrcode'])
    }
  },
  {
    path: '/monitoring/jalur-darat/form/type-2/wizard',
    name: 'monitoring-jalur-darat-form-type-2-wizard',
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-darat/form-type-2/wizard/index.vue'
      ),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Form Type 2 (T2)',
          to: '/monitoring/jalur-darat/form/type-2'
        },
        {
          text: 'Buat Form',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form2', 'qrcode'])
    }
  },
  {
    path: '/monitoring/jalur-darat/form/type-3',
    name: 'monitoring-jalur-darat-form-type-3',
    component: () =>
      import('@/views/pages/monitoring/jalur-darat/form-type-3/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Form Type 3 (T3)',
          active: true
        },
        {
          text: 'index',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form3', 'qrcode'])
    }
  },
  /* END NAVIGASI TERBITKAN FORM --> JALUR DARAT */

  {
    path: '/monitoring/jalur-darat/form/type-3/wizard',
    name: 'monitoring-jalur-darat-form-type-3-wizard',
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-darat/form-type-3/wizard/index.vue'
      ),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Form Type 3 (T3)',
          to: '/monitoring/jalur-darat/form/type-3'
        },
        {
          text: 'Buat Form',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form3', 'qrcode'])
    }
  },
  {
    path: '/dashboard/monitoring/lrv/mon-1',
    name: 'dashboard-monitoring-lrv-mon-1',
    component: () =>
      import('@/views/pages/dashboard/land-monitoring/LandMon1.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Pending Approval',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form1', 'qrcode', 'special'])
    }
  },
  {
    path: '/dashboard/monitoring/lrv/mon-5',
    name: 'dashboard-monitoring-lrv-mon-5',
    component: () =>
      import('@/views/pages/dashboard/land-monitoring/LandMon5.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Approved',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form1', 'qrcode', 'special'])
    }
  },
  {
    path: '/dashboard/monitoring/lrv/mon-2',
    name: 'dashboard-monitoring-lrv-mon-2',
    component: () =>
      import('@/views/pages/dashboard/land-monitoring/LandMon2.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Clock IN',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form1', 'qrcode', 'special'])
    }
  },
  {
    path: '/dashboard/monitoring/lrv/mon-3',
    name: 'dashboard-monitoring-lrv-mon-3',
    component: () =>
      import('@/views/pages/dashboard/land-monitoring/LandMon3.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Used',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form1', 'qrcode', 'special'])
    }
  },
  {
    path: '/dashboard/monitoring/lrv/mon-4',
    name: 'dashboard-monitoring-lrv-mon-4',
    component: () =>
      import('@/views/pages/dashboard/land-monitoring/LandMon4.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Darat',
          active: true
        },
        {
          text: 'Expired',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'form1', 'qrcode', 'special'])
    }
  },

  /* OPERASIONAL */
  {
    path: '/form/kegiatan-operasional',
    name: 'form-kegiatan-operasional',
    component: () => import('@/views/pages/kegiatan/operasional/index.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Kegiatan',
          active: true
        },
        {
          text: 'Operasional Dermaga',
          active: true
        },
        {
          text: 'Kegiatan bongkar atau Muat di Dermaga',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'Operasional'])
    }
  },
  {
    path: '/form/kegiatan-operasional/create',
    name: 'form-kegiatan-operasional-create',
    component: () =>
      import('@/views/pages/kegiatan/operasional/_create_edit.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Kegiatan',
          active: true
        },
        {
          text: 'Operasional Dermaga',
          to: '/form/kegiatan-operasional'
        },
        {
          text: 'Kegiatan bongkar atau Muat di Dermaga',
          active: true
        },
        {
          text: 'Create',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'Operasional'])
    }
  },
  {
    path: '/form/kegiatan-operasional/view',
    name: 'form-kegiatan-operasional-view',
    component: () =>
      import('@/views/pages/kegiatan/operasional/_create_edit.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Kegiatan',
          active: true
        },
        {
          text: 'Operasional Dermaga',
          to: '/form/kegiatan-operasional'
        },
        {
          text: 'Kegiatan bongkar atau Muat di Dermaga',
          active: true
        },
        {
          text: 'View atau Edit',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'Operasional'])
    }
  },
  /* END OF OPERASIONAL */

  /* JASA BARANG */
  {
    path: '/form/laporan/kegiatan-operasional',
    name: 'form-kegiatan-operasional-laporan',
    component: () =>
      import('@/views/pages/kegiatan/laporan-operasional/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Kegiatan',
          active: true
        },
        {
          text: 'Jasa Barang',
          active: true
        },
        {
          text: '( Laporan Kegiatan Operasional )',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'Jasa Barang'])
    }
  },
  /* non admin */
  {
    path: '/form/laporan/kegiatan-operasional/create',
    name: 'form-kegiatan-operasional-laporan-create',
    component: () =>
      import('@/views/pages/kegiatan/laporan-operasional/_create_edit.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Kegiatan',
          active: true
        },
        {
          text: 'Jasa Barang',
          to: '/form/laporan/kegiatan-operasional'
        },
        {
          text: '( Laporan Kegiatan Operasional )',
          active: true
        },
        {
          text: 'Create',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'Jasa Barang'])
    }
  },
  {
    path: '/form/laporan/kegiatan-operasional/view',
    name: 'form-kegiatan-operasional-laporan-view',
    component: () =>
      import('@/views/pages/kegiatan/laporan-operasional/_create_edit.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Kegiatan',
          active: true
        },
        {
          text: 'Jasa Barang',
          to: '/form/laporan/kegiatan-operasional'
        },
        {
          text: '( Laporan Kegiatan Operasional )',
          active: true
        },
        {
          text: 'View atau Edit',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'Jasa Barang'])
    }
  },

  /* admin */
  {
    path: '/form/laporan/kegiatan-operasional/realisasi/:id',
    name: 'form-kegiatan-operasional-laporan-realisasi',
    component: () =>
      import('@/views/pages/kegiatan/laporan-operasional/realisasi/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Kegiatan',
          active: true
        },
        {
          text: 'Jasa Barang',
          to: '/form/laporan/kegiatan-operasional'
        },
        {
          text: '( Laporan Kegiatan Operasional )',
          active: true
        },
        {
          text: 'Realisasi (Admin SAL)',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all', 'Jasa Barang'], ['SAL'])
    }
  },
  {
    path: '/form/laporan/kegiatan-operasional/realisasi/create',
    name: 'form-kegiatan-operasional-laporan-realisasi-create',
    component: () =>
      import(
        '@/views/pages/kegiatan/laporan-operasional/realisasi/_create_edit.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Kegiatan',
          active: true
        },
        {
          text: 'Jasa Barang',
          to: '/form/laporan/kegiatan-operasional'
        },
        {
          text: '( Laporan Kegiatan Operasional )',
          active: true
        },
        {
          text: 'Realisasi (Admin SAL)',
          to: '/form/laporan/kegiatan-operasional/realisasi/:id',
          active: true
        },
        {
          text: 'Create',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all'], ['SAL'])
    }
  },
  {
    path: '/form/laporan/kegiatan-operasional/realisasi/view',
    name: 'form-kegiatan-operasional-laporan-realisasi-view',
    component: () =>
      import(
        '@/views/pages/kegiatan/laporan-operasional/realisasi/_create_edit.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Kegiatan',
          active: true
        },
        {
          text: 'Jasa Barang',
          to: '/form/laporan/kegiatan-operasional'
        },
        {
          text: '( Laporan Kegiatan Operasional )',
          active: true
        },
        {
          text: 'Realisasi (Admin SAL)',
          to: '/form/laporan/kegiatan-operasional/realisasi/:id',
          active: true
        },
        {
          text: 'View atau Edit',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all'], ['SAL'])
    }
  }

  /* END OF JASA BARANG */
]
