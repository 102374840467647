import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = next => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (['superadmin', 'host'].includes(role)) { // allow click menu
      let flagContinue = false
      ability.map(a => {
        if (flagContinue === false) {
          flagContinue = ['user', 'all'].includes(a.subject)
        }
        return a
      })
      if (flagContinue) {
        return next()
      }
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/sa/users',
    name: 'sa-users',
    component: () => import('@/views/pages/superadmin/Users.vue'),
  },
  {
    path: '/sa/groups',
    name: 'sa-groups',
    component: () => import('@/views/pages/superadmin/Group.vue'),
  },
  {
    path: '/sa/coming-soon',
    name: 'coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
  },
]
  .map(route => {
    // eslint-disable-next-line no-param-reassign
    route.meta = { guard }
    return route
  })
