/* eslint-disable camelcase */
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import auth from './routes/auth'
import routerSuperadmin from './routes/router_superadmin'
import routerClient from './routes/router_client'
import miscellaneous from './routes/miscellaneous'
import router_data_master from './routes/router_data_master'
import router_company_own from './routes/router_company_own'
import router_land from './routes/router_land'
import router_river from './routes/router_river'
import router_host from './routes/router_host'
import router_report from './routes/router_report'
import router_analytic from './routes/router_analytic'

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: {
        name: 'auth-login'
      }
    },
    ...routerSuperadmin,
    ...routerClient,
    ...router_host,
    ...router_land,
    ...router_river,
    ...router_analytic,
    ...router_data_master,
    ...router_company_own,
    ...router_report,
    ...auth,
    ...miscellaneous,
    { path: '*', redirect: 'error-404' }
  ]
})

router.beforeEach((to, from, next) => {
  // #TODO IMPLEMENTATION AUTHENTICATION WITH BE
  if ('guard' in to.meta) {
    return to.meta.guard(next)
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
