var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-100",
    class: [_vm.skinClasses],
    attrs: {
      "id": "app"
    }
  }, [_c(_vm.layout, {
    tag: "component"
  }, [_c('router-view')], 1), _vm.enableScrollToTop ? _c('scroll-to-top') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }